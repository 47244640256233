import instagram from "../../../assets/instagram.webp";
import instagramblack from "../../../assets/instagram-black.png";
import instagramwhite from "../../../assets/instagram-white.png";
import facebook from "../../../assets/facebook.webp";
import facebookblack from "../../../assets/facebook-black.png";
import facebookwhite from "../../../assets/facebook-white.png";
import twitter from "../../../assets/twitter.svg";
import twitterblack from "../../../assets/twitter-black.svg";
import twitterwhite from "../../../assets/twitter-white.svg";
import youtube from "../../../assets/youtube.png";
import youtubeblack from "../../../assets/youtube-black.png";

// "props" needs to be an object containing properties url, title, and logoColor
export default function SocialIcons({
  props,
  divClasses = "",
  linkClasses = "",
  imgClasses = "",
}: any) {
  // these nested objects will hold the images for all future logos
  // the index signature ({ [k: string]: { [key: string]: string } })
  // lets the compiler know which types we're accepting
  // for both our keys and values when referencing our logoObject
  // this must be explicitly typed since we're using indexed access types below
  const logoObject: { [key: string]: { [key: string]: string } } = {
    instagram: {
      black: instagramblack,
      white: instagramwhite,
      regular: instagram,
    },

    facebook: {
      black: facebookblack,
      white: facebookwhite,
      regular: facebook,
    },

    twitter: {
      black: twitterblack,
      white: twitterwhite,
      regular: twitter,
    },

    youtube: {
      black: youtubeblack,
      regular: youtube,
    },
  };

  // prop destructuring
  const { url, title, logoColor } = props;

  let socialLogo;
  // Running checks to avoid any errors
  // Checks if title sent from control panel exists in logoObject
  if (logoObject.hasOwnProperty(title?.toLowerCase())) {
    // if it does exist, check if there is a valid logoColor associated with it
    if (logoObject[title?.toLowerCase()][logoColor]) {
      socialLogo = logoObject[title?.toLowerCase()][logoColor];
    }
    // if there isn't, default to the base logo
    else {
      socialLogo = logoObject[title?.toLowerCase()]["regular"];
    }
  }
  // if the title doesn't exist at all, nothing will be rendered
  else {
    socialLogo = "";
  }

  return (
    <div className={divClasses}>
      <a
        href={url}
        rel="noreferrer noopener"
        target="_blank"
        aria-label={title}
        className={linkClasses}
      >
        <img className={imgClasses} src={socialLogo} alt={`${title} Logo`} />
      </a>
    </div>
  );
}
